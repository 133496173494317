/*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-includes/css/dist/block-library/style.min.css?ver=6.2.2 ; media=all */
@media all{
    ul{box-sizing:border-box;}
    html :where(img[class*=wp-image-]){height:auto;max-width:100%;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/themes/hello-elementor/style.min.css?ver=2.8.1 ; media=all */
    @media all{
    *,:after,:before{box-sizing:border-box;}
    h2,h3,h4{-webkit-margin-before:.5rem;margin-block-start:.5rem;-webkit-margin-after:1rem;margin-block-end:1rem;font-family:inherit;font-weight:500;line-height:1.2;color:inherit;}
    h2{font-size:2rem;}
    h3{font-size:1.75rem;}
    h4{font-size:1.5rem;}
    p{-webkit-margin-before:0;margin-block-start:0;-webkit-margin-after:.9rem;margin-block-end:.9rem;}
    a{background-color:transparent;text-decoration:none;color:#c36;}
    a:active,a:hover{color:#336;}
    strong{font-weight:bolder;}
    img{border-style:none;height:auto;max-width:100%;}
    @media print{
    *,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
    a,a:visited{text-decoration:underline;}
    a[href]:after{content:" (" attr(href) ")";}
    a[href^="#"]:after{content:"";}
    img{-moz-column-break-inside:avoid;break-inside:avoid;}
    h2,h3,p{orphans:3;widows:3;}
    h2,h3{-moz-column-break-after:avoid;break-after:avoid;}
    }
    li,ul{-webkit-margin-before:0;margin-block-start:0;-webkit-margin-after:0;margin-block-end:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.15.1 ; media=all */
    @media all{
    .elementor *,.elementor :after,.elementor :before{box-sizing:border-box;}
    .elementor a{box-shadow:none;text-decoration:none;}
    .elementor img{height:auto;max-width:100%;border:none;border-radius:0;box-shadow:none;}
    .elementor .elementor-background-overlay{height:100%;width:100%;top:0;left:0;position:absolute;}
    .elementor-element{--flex-direction:initial;--flex-wrap:initial;--justify-content:initial;--align-items:initial;--align-content:initial;--gap:initial;--flex-basis:initial;--flex-grow:initial;--flex-shrink:initial;--order:initial;--align-self:initial;flex-basis:var(--flex-basis);flex-grow:var(--flex-grow);flex-shrink:var(--flex-shrink);order:var(--order);align-self:var(--align-self);}
    .elementor-element:where(.e-con-full,.elementor-widget){flex-direction:var(--flex-direction);flex-wrap:var(--flex-wrap);justify-content:var(--justify-content);align-items:var(--align-items);align-content:var(--align-content);gap:var(--gap);}
    .elementor-align-center{text-align:center;}
    .elementor-align-center .elementor-button{width:auto;}
    .elementor-section{position:relative;}
    .elementor-section .elementor-container{display:flex;margin-right:auto;margin-left:auto;position:relative;}
    @media (max-width:1024px){
    .elementor-section .elementor-container{flex-wrap:wrap;}
    }
    .elementor-section.elementor-section-boxed>.elementor-container{max-width:1140px;}
    .elementor-section.elementor-section-items-middle>.elementor-container{align-items:center;}
    .elementor-widget-wrap{position:relative;width:100%;flex-wrap:wrap;align-content:flex-start;}
    .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap{display:flex;}
    .elementor-widget-wrap>.elementor-element{width:100%;}
    .elementor-widget-wrap.e-swiper-container{width:calc(100% - (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px)));}
    .elementor-widget{position:relative;}
    .elementor-widget:not(:last-child){margin-bottom:20px;}
    .elementor-column{position:relative;min-height:1px;display:flex;}
    .elementor-column-gap-default>.elementor-column>.elementor-element-populated{padding:10px;}
    @media (min-width:768px){
    .elementor-column.elementor-col-25{width:25%;}
    .elementor-column.elementor-col-33{width:33.333%;}
    .elementor-column.elementor-col-50{width:50%;}
    .elementor-column.elementor-col-100{width:100%;}
    }
    @media (min-width:768px) and (max-width:1024px){
    .elementor-reverse-tablet>.elementor-container>:first-child{order:10;}
    .elementor-reverse-tablet>.elementor-container>:nth-child(2){order:9;}
    }
    @media (min-width:-1) and (max-width:1024px){
    .elementor-reverse-tablet>.elementor-container>:first-child{order:10;}
    .elementor-reverse-tablet>.elementor-container>:nth-child(2){order:9;}
    }
    @media (min-width:768px) and (max-width:-1){
    .elementor-reverse-tablet>.elementor-container>:first-child,.elementor-reverse-tablet>.elementor-container>:nth-child(2){order:0;}
    }
    @media (max-width:767px){
    .elementor-reverse-mobile>.elementor-container>:first-child{order:10;}
    .elementor-reverse-mobile>.elementor-container>:nth-child(2){order:9;}
    .elementor-column{width:100%;}
    }
    .elementor-element .elementor-widget-container{transition:background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);}
    .elementor-button{display:inline-block;line-height:1;background-color:#69727d;font-size:15px;padding:12px 24px;border-radius:3px;color:#fff;fill:#fff;text-align:center;transition:all .3s;}
    .elementor-button:focus,.elementor-button:hover,.elementor-button:visited{color:#fff;}
    .elementor-button-content-wrapper{display:flex;justify-content:center;}
    .elementor-button-text{flex-grow:1;order:10;display:inline-block;}
    .elementor-button span{text-decoration:inherit;}
    .elementor-icon{display:inline-block;line-height:1;transition:all .3s;color:#69727d;font-size:50px;text-align:center;}
    .elementor-icon:hover{color:#69727d;}
    .elementor-icon i{width:1em;height:1em;position:relative;display:block;}
    .elementor-icon i:before{position:absolute;left:50%;transform:translateX(-50%);}
    .elementor-element{--swiper-theme-color:#000;--swiper-navigation-size:44px;--swiper-pagination-bullet-size:6px;--swiper-pagination-bullet-horizontal-gap:6px;}
    .animated{animation-duration:1.25s;}
    .animated.animated-slow{animation-duration:2s;}
    @media (prefers-reduced-motion:reduce){
    .animated{animation:none;}
    }
    .elementor .elementor-element ul.elementor-icon-list-items{padding:0;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/elementor/css/post-3.css?ver=1678682998 ; media=all */
    @media all{
    .elementor-kit-3 a{color:var( --e-global-color-accent );}
    .elementor-kit-3 a:hover{color:var( --e-global-color-b0a5f9a );}
    .elementor-kit-3 h2{color:var( --e-global-color-primary );font-family:var( --e-global-typography-ba5b015-font-family ), Sans-serif;font-size:var( --e-global-typography-ba5b015-font-size );font-weight:var( --e-global-typography-ba5b015-font-weight );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-kit-3 h3{color:var( --e-global-color-primary );font-family:var( --e-global-typography-8c2b331-font-family ), Sans-serif;font-size:var( --e-global-typography-8c2b331-font-size );font-weight:var( --e-global-typography-8c2b331-font-weight );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-kit-3 h4{color:var( --e-global-color-primary );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-kit-3 .elementor-button{font-family:var( --e-global-typography-accent-font-family ), Sans-serif;font-size:var( --e-global-typography-accent-font-size );font-weight:var( --e-global-typography-accent-font-weight );line-height:var( --e-global-typography-accent-line-height );letter-spacing:var( --e-global-typography-accent-letter-spacing );color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );border-radius:20px 0px 20px 20px;padding:20px 20px 20px 20px;}
    .elementor-kit-3 .elementor-button:hover,.elementor-kit-3 .elementor-button:focus{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );border-radius:20px 0px 20px 20px;}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1340px;}
    .elementor-widget:not(:last-child){margin-bottom:20px;}
    .elementor-element{--widgets-spacing:20px;}
    @media (max-width:1024px){
    .elementor-kit-3 h2{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-kit-3 h3{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-kit-3 h4{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-kit-3 .elementor-button{font-size:var( --e-global-typography-accent-font-size );line-height:var( --e-global-typography-accent-line-height );letter-spacing:var( --e-global-typography-accent-letter-spacing );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1024px;}
    }
    @media (max-width:767px){
    .elementor-kit-3 h2{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-kit-3 h3{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-kit-3 h4{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-kit-3 .elementor-button{font-size:var( --e-global-typography-accent-font-size );line-height:var( --e-global-typography-accent-line-height );letter-spacing:var( --e-global-typography-accent-letter-spacing );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:767px;}
    }
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementskit-lite/modules/elementskit-icon-pack/assets/css/ekiticons.css?ver=2.9.0 ; media=all */
    @media all{
    .ekit-wid-con .icon,.ekit-wid-con .icon::before,.icon,.icon::before{font-family:elementskit!important;speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
    .ekit-wid-con .icon.icon-quote::before,.icon.icon-quote::before{content:"\e97e";}
    .icon.icon-team1::before{content:"\e9c7";}
    .ekit-wid-con .icon.icon-users2::before,.icon.icon-users2::before{content:"\ea9a";}
    .ekit-wid-con .icon.icon-hand_shake::before,.icon.icon-hand_shake::before{content:"\eabb";}
    .ekit-wid-con .icon.icon-doctor::before,.icon.icon-doctor::before{content:"\ead5";}
    .icon.icon-flower::before{content:"\eb0f";}
    .ekit-wid-con .icon.icon-badge::before,.icon.icon-badge::before{content:"\eb36";}
    .ekit-wid-con .icon.icon-facebook::before,.icon.icon-facebook::before{content:"\eb43";}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css?ver=8.4.5 ; media=all */
    @media all{
    .swiper{margin-left:auto;margin-right:auto;position:relative;overflow:hidden;list-style:none;padding:0;z-index:1;}
    .swiper-wrapper{position:relative;width:100%;height:100%;z-index:1;display:flex;transition-property:transform;box-sizing:content-box;}
    .swiper-wrapper{transform:translate3d(0px,0,0);}
    .swiper-pointer-events{touch-action:pan-y;}
    .swiper-slide{flex-shrink:0;width:100%;height:100%;position:relative;transition-property:transform;}
    .swiper-backface-hidden .swiper-slide{transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden;}
    .swiper .swiper-notification{position:absolute;left:0;top:0;pointer-events:none;opacity:0;z-index:-1000;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/elementor/css/post-366.css?ver=1678703944 ; media=all */
    @media all{
    .elementor-366 .elementor-element.elementor-element-1316e5f > .elementor-container{min-height:50vh;}
    .elementor-366 .elementor-element.elementor-element-1316e5f:not(.elementor-motion-effects-element-type-background){background-image:url("https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/mental-health-support-holding-hands-and-group-coun-3QDQQDVa.jpg");background-position:center center;background-size:cover;}
    .elementor-366 .elementor-element.elementor-element-1316e5f > .elementor-background-overlay{background-color:var( --e-global-color-25b493e );opacity:0.7;transition:background 0.3s, border-radius 0.3s, opacity 0.3s;}
    .elementor-366 .elementor-element.elementor-element-1316e5f{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;}
    .elementor-366 .elementor-element.elementor-element-7e6ea6a > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title{color:var( --e-global-color-accent );margin:0px 0px 0px 0px;font-family:var( --e-global-typography-ba5b015-font-family ), Sans-serif;font-size:var( --e-global-typography-ba5b015-font-size );font-weight:var( --e-global-typography-ba5b015-font-weight );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title:hover{color:var( --e-global-color-accent );}
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title > span{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title:hover > span{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title span:last-child,.elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title > span{font-family:var( --e-global-typography-ba5b015-font-family ), Sans-serif;font-size:var( --e-global-typography-ba5b015-font-size );font-weight:var( --e-global-typography-ba5b015-font-weight );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-04fd32c{text-align:center;color:var( --e-global-color-accent );}
    .elementor-366 .elementor-element.elementor-element-4777ede{overflow:hidden;padding:6em 1em 6em 1em;}
    .elementor-366 .elementor-element.elementor-element-f7aafba.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-f7aafba > .elementor-element-populated{margin:0em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-296d0d3{text-align:left;}
    .elementor-366 .elementor-element.elementor-element-296d0d3 .elementor-heading-title{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-a6f85b6-font-family ), Sans-serif;font-size:var( --e-global-typography-a6f85b6-font-size );font-weight:var( --e-global-typography-a6f85b6-font-weight );font-style:var( --e-global-typography-a6f85b6-font-style );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-f3d8465 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-1a65318{text-align:left;}
    .elementor-366 .elementor-element.elementor-element-609a14d > .elementor-element-populated{padding:0px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-70e13c0.elementor-view-default .elementor-icon{fill:var( --e-global-color-b0a5f9a );color:var( --e-global-color-b0a5f9a );border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-70e13c0{--icon-box-icon-margin:20px;}
    .elementor-366 .elementor-element.elementor-element-70e13c0 .elementor-icon{font-size:50px;}
    .elementor-366 .elementor-element.elementor-element-70e13c0 .elementor-icon-box-title{margin-bottom:5px;}
    .elementor-366 .elementor-element.elementor-element-70e13c0 > .elementor-widget-container{margin:0em 1em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-4d538ab > .elementor-element-populated{padding:0px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-2468123.elementor-view-default .elementor-icon{fill:var( --e-global-color-b0a5f9a );color:var( --e-global-color-b0a5f9a );border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-2468123{--icon-box-icon-margin:20px;}
    .elementor-366 .elementor-element.elementor-element-2468123 .elementor-icon{font-size:50px;}
    .elementor-366 .elementor-element.elementor-element-2468123 .elementor-icon-box-title{margin-bottom:5px;}
    .elementor-366 .elementor-element.elementor-element-e563791{text-align:left;color:var( --e-global-color-25b493e );font-family:var( --e-global-typography-6185b3a-font-family ), Sans-serif;font-size:var( --e-global-typography-6185b3a-font-size );font-weight:var( --e-global-typography-6185b3a-font-weight );font-style:var( --e-global-typography-6185b3a-font-style );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e563791 > .elementor-widget-container{margin:1em 0em 0em 0em;padding:1em 0em 0.5em 1.5em;border-style:solid;border-width:0px 0px 0px 3px;border-color:var( --e-global-color-b0a5f9a );border-radius:50px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-19ccd47.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-19ccd47 > .elementor-element-populated{margin:0em 0em 0em 4em;--e-column-margin-right:0em;--e-column-margin-left:4em;padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-b601bcd img{border-style:solid;border-width:0px 0px 10px 10px;border-color:var( --e-global-color-secondary );border-radius:50px 0px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-b601bcd > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-b601bcd{z-index:1;}
    .elementor-366 .elementor-element.elementor-element-bdc7a82{--spacer-size:605px;}
    .elementor-366 .elementor-element.elementor-element-bdc7a82 > .elementor-widget-container{margin:-33.5em 5em 0em -2em;background-color:var( --e-global-color-25b493e );border-radius:50px 0px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact{justify-content:center;display:flex;background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:10px 10px 0px 0px;border-color:var( --e-global-color-secondary );border-radius:50px 50px 50px 0px;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .funfact-content .number-percentage-wraper{color:var( --e-global-color-accent );font-family:var( --e-global-typography-8c2b331-font-family ), Sans-serif;font-size:var( --e-global-typography-8c2b331-font-size );font-weight:var( --e-global-typography-8c2b331-font-weight );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .funfact-content .number-percentage{margin-right:0px;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .funfact-title{color:var( --e-global-color-accent );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .super{color:var( --e-global-color-accent );font-family:var( --e-global-typography-58cfe92-font-family ), Sans-serif;font-size:var( --e-global-typography-58cfe92-font-size );font-weight:var( --e-global-typography-58cfe92-font-weight );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );top:5px;left:0px;vertical-align:super;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .elementskit-funfact-inner{padding:2em 2em 2em 2em;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef > .elementor-widget-container{margin:-8.5em 18em 0em -4em;}
    .elementor-366 .elementor-element.elementor-element-b57e4b3:not(.elementor-motion-effects-element-type-background){background-image:url("https://therapytreeaz.com/wp-content/uploads/2019/08/group-therapy.jpg");background-position:center center;background-size:cover;}
    .elementor-366 .elementor-element.elementor-element-b57e4b3 > .elementor-background-overlay{background-color:var( --e-global-color-25b493e );opacity:0.7;transition:background 0.3s, border-radius 0.3s, opacity 0.3s;}
    .elementor-366 .elementor-element.elementor-element-b57e4b3{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;padding:6em 1em 6em 1em;}
    .elementor-366 .elementor-element.elementor-element-c3d4d04 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .elementskit-funfact-icon{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact-icon{font-size:65px;}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact-icon{margin-bottom:15px;padding:0px;transform:rotate(0deg);}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact{justify-content:center;display:flex;}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .funfact-content .number-percentage-wraper{color:var( --e-global-color-accent );font-family:var( --e-global-typography-8c2b331-font-family ), Sans-serif;font-size:var( --e-global-typography-8c2b331-font-size );font-weight:var( --e-global-typography-8c2b331-font-weight );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .funfact-content .number-percentage{margin-right:0px;}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .funfact-title{color:var( --e-global-color-accent );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .super{color:var( --e-global-color-accent );font-family:var( --e-global-typography-58cfe92-font-family ), Sans-serif;font-size:var( --e-global-typography-58cfe92-font-size );font-weight:var( --e-global-typography-58cfe92-font-weight );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );top:5px;left:0px;vertical-align:super;}
    .elementor-366 .elementor-element.elementor-element-8516033 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .elementskit-funfact-icon{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact-icon{font-size:65px;}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact-icon{margin-bottom:15px;padding:0px;transform:rotate(0deg);}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact{justify-content:center;display:flex;}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .funfact-content .number-percentage-wraper{color:var( --e-global-color-accent );font-family:var( --e-global-typography-8c2b331-font-family ), Sans-serif;font-size:var( --e-global-typography-8c2b331-font-size );font-weight:var( --e-global-typography-8c2b331-font-weight );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .funfact-content .number-percentage{margin-right:0px;}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .funfact-title{color:var( --e-global-color-accent );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .super{color:var( --e-global-color-accent );font-family:var( --e-global-typography-58cfe92-font-family ), Sans-serif;font-size:var( --e-global-typography-58cfe92-font-size );font-weight:var( --e-global-typography-58cfe92-font-weight );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );top:5px;left:0px;vertical-align:super;}
    .elementor-366 .elementor-element.elementor-element-1f5d813 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .elementskit-funfact-icon{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact-icon{font-size:65px;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact-icon{margin-bottom:15px;padding:0px;transform:rotate(0deg);}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact{justify-content:center;display:flex;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .funfact-content .number-percentage-wraper{color:var( --e-global-color-accent );font-family:var( --e-global-typography-8c2b331-font-family ), Sans-serif;font-size:var( --e-global-typography-8c2b331-font-size );font-weight:var( --e-global-typography-8c2b331-font-weight );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .funfact-content .number-percentage{margin-right:0px;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .funfact-title{color:var( --e-global-color-accent );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .super{color:var( --e-global-color-accent );font-family:var( --e-global-typography-58cfe92-font-family ), Sans-serif;font-size:var( --e-global-typography-58cfe92-font-size );font-weight:var( --e-global-typography-58cfe92-font-weight );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );top:5px;left:0px;vertical-align:super;}
    .elementor-366 .elementor-element.elementor-element-ad3384c > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .elementskit-funfact-icon{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact-icon{font-size:65px;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact-icon{margin-bottom:15px;padding:0px;transform:rotate(0deg);}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact{justify-content:center;display:flex;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .funfact-content .number-percentage-wraper{color:var( --e-global-color-accent );font-family:var( --e-global-typography-8c2b331-font-family ), Sans-serif;font-size:var( --e-global-typography-8c2b331-font-size );font-weight:var( --e-global-typography-8c2b331-font-weight );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .funfact-content .number-percentage{margin-right:0px;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .funfact-title{color:var( --e-global-color-accent );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .super{color:var( --e-global-color-accent );font-family:var( --e-global-typography-58cfe92-font-family ), Sans-serif;font-size:var( --e-global-typography-58cfe92-font-size );font-weight:var( --e-global-typography-58cfe92-font-weight );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );top:5px;left:0px;vertical-align:super;}
    .elementor-366 .elementor-element.elementor-element-400bbe7{overflow:hidden;padding:6em 1em 3em 1em;}
    .elementor-366 .elementor-element.elementor-element-8031c76.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-8031c76 > .elementor-element-populated{margin:0em 6em 0em 0em;--e-column-margin-right:6em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-4df6451 img{border-style:solid;border-width:0px 10px 10px 0px;border-color:var( --e-global-color-secondary );border-radius:0px 50px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-4df6451 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-4df6451{z-index:1;}
    .elementor-366 .elementor-element.elementor-element-f924706{--spacer-size:555px;}
    .elementor-366 .elementor-element.elementor-element-f924706 > .elementor-widget-container{margin:-30.6em -2em 0em 5em;background-color:var( --e-global-color-25b493e );border-radius:50px 50px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-ef4e20b img{border-style:solid;border-width:10px 0px 0px 10px;border-color:var( --e-global-color-secondary );border-radius:50px 50px 0px 50px;}
    .elementor-366 .elementor-element.elementor-element-ef4e20b > .elementor-widget-container{margin:-10em -4em 0em 18em;border-radius:0px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-ef4e20b{z-index:1;}
    .elementor-366 .elementor-element.elementor-element-4ce8b0b.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-4ce8b0b > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-7ebb03b{text-align:left;}
    .elementor-366 .elementor-element.elementor-element-7ebb03b .elementor-heading-title{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-a6f85b6-font-family ), Sans-serif;font-size:var( --e-global-typography-a6f85b6-font-size );font-weight:var( --e-global-typography-a6f85b6-font-weight );font-style:var( --e-global-typography-a6f85b6-font-style );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-b34f2d0 > .elementor-widget-container{margin:0em 2em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-5593f33{text-align:left;}
    .elementor-366 .elementor-element.elementor-element-1ec5c06 > .elementor-element-populated{padding:0px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-921e1a1 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child){padding-bottom:calc(5px/2);}
    .elementor-366 .elementor-element.elementor-element-921e1a1 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child){margin-top:calc(5px/2);}
    .elementor-366 .elementor-element.elementor-element-921e1a1 .elementor-icon-list-icon i{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-921e1a1 .elementor-icon-list-item:hover .elementor-icon-list-icon i{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-921e1a1{--e-icon-list-icon-size:15px;}
    .elementor-366 .elementor-element.elementor-element-921e1a1 .elementor-icon-list-text{color:var( --e-global-color-primary );padding-left:0px;}
    .elementor-366 .elementor-element.elementor-element-921e1a1 .elementor-icon-list-item:hover .elementor-icon-list-text{color:var( --e-global-color-primary );}
    .elementor-366 .elementor-element.elementor-element-921e1a1 > .elementor-widget-container{margin:-3% 0% 0% 0%;}
    .elementor-366 .elementor-element.elementor-element-7ae4dcd > .elementor-element-populated{padding:0px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child){padding-bottom:calc(5px/2);}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child){margin-top:calc(5px/2);}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd .elementor-icon-list-icon i{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd .elementor-icon-list-item:hover .elementor-icon-list-icon i{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd{--e-icon-list-icon-size:15px;}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd .elementor-icon-list-text{color:var( --e-global-color-primary );padding-left:0px;}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd .elementor-icon-list-item:hover .elementor-icon-list-text{color:var( --e-global-color-primary );}
    .elementor-366 .elementor-element.elementor-element-f9cf8bd > .elementor-widget-container{margin:-3% 0% 0% 0%;}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .number-percentage-wraper{display:no;color:var( --e-global-color-accent );font-family:var( --e-global-typography-734265e-font-family ), Sans-serif;font-size:var( --e-global-typography-734265e-font-size );font-weight:var( --e-global-typography-734265e-font-weight );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .skill-bar{background-color:var( --e-global-color-25b493e );height:10px;border-radius:5px 5px 5px 5px;}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .skill-track{background-color:var( --e-global-color-b0a5f9a );border-radius:5px 5px 5px 5px;}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .skill-title{color:var( --e-global-color-primary );font-family:var( --e-global-typography-734265e-font-family ), Sans-serif;font-size:var( --e-global-typography-734265e-font-size );font-weight:var( --e-global-typography-734265e-font-weight );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-79e171d .skill-bar-content{margin-bottom:13px;}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .single-skill-bar .svg-content > svg{fill:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-79e171d > .elementor-widget-container{margin:2em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .number-percentage-wraper{display:no;color:var( --e-global-color-accent );font-family:var( --e-global-typography-734265e-font-family ), Sans-serif;font-size:var( --e-global-typography-734265e-font-size );font-weight:var( --e-global-typography-734265e-font-weight );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .skill-bar{background-color:var( --e-global-color-25b493e );height:10px;border-radius:5px 5px 5px 5px;}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .skill-track{background-color:var( --e-global-color-b0a5f9a );border-radius:5px 5px 5px 5px;}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .skill-title{color:var( --e-global-color-primary );font-family:var( --e-global-typography-734265e-font-family ), Sans-serif;font-size:var( --e-global-typography-734265e-font-size );font-weight:var( --e-global-typography-734265e-font-weight );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skill-bar-content{margin-bottom:13px;}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .single-skill-bar .svg-content > svg{fill:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-27373b4 > .elementor-widget-container{margin:1em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .number-percentage-wraper{display:no;color:var( --e-global-color-accent );font-family:var( --e-global-typography-734265e-font-family ), Sans-serif;font-size:var( --e-global-typography-734265e-font-size );font-weight:var( --e-global-typography-734265e-font-weight );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .skill-bar{background-color:var( --e-global-color-25b493e );height:10px;border-radius:5px 5px 5px 5px;}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .skill-track{background-color:var( --e-global-color-b0a5f9a );border-radius:5px 5px 5px 5px;}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .skill-title{color:var( --e-global-color-primary );font-family:var( --e-global-typography-734265e-font-family ), Sans-serif;font-size:var( --e-global-typography-734265e-font-size );font-weight:var( --e-global-typography-734265e-font-weight );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skill-bar-content{margin-bottom:13px;}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .single-skill-bar .svg-content > svg{fill:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-c9efd72 > .elementor-widget-container{margin:1em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-cd63d8b{padding:3em 1em 6em 1em;}
    .elementor-366 .elementor-element.elementor-element-35281f4 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;--ekit_testimonial_slidetoshow:1;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider{padding:3em 3em 3em 3em;box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0);}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider{border-radius:50px 0px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider{background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{color:var( --e-global-color-7e6a25d );margin:0em 0em 1em 0em;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-family:var( --e-global-typography-6185b3a-font-family ), Sans-serif;font-size:var( --e-global-typography-6185b3a-font-size );font-weight:var( --e-global-typography-6185b3a-font-weight );font-style:var( --e-global-typography-6185b3a-font-style );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider .elementskit-watermark-icon > i{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-watermark-icon > i{font-size:55px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-profile-info .elementskit-author-name{color:var( --e-global-color-primary );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-profile-info .elementskit-author-des{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-ec51179-font-family ), Sans-serif;font-size:var( --e-global-typography-ec51179-font-size );font-weight:var( --e-global-typography-ec51179-font-weight );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-commentor-bio{margin:0px 0px 0px 0px;bottom:-100px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-commentor-bio .elementskit-commentor-image > img{height:70px;width:70px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .ekit-testimonial--avatar{margin:0px 0px 5px 0px;}
    .elementor-366 .elementor-element.elementor-element-00a37b1 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;--ekit_testimonial_slidetoshow:1;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider{padding:3em 3em 3em 3em;box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0);}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider{border-radius:50px 50px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider{background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{color:var( --e-global-color-7e6a25d );margin:0em 0em 1em 0em;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-family:var( --e-global-typography-6185b3a-font-family ), Sans-serif;font-size:var( --e-global-typography-6185b3a-font-size );font-weight:var( --e-global-typography-6185b3a-font-weight );font-style:var( --e-global-typography-6185b3a-font-style );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider .elementskit-watermark-icon > i{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-watermark-icon > i{font-size:55px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-profile-info .elementskit-author-name{color:var( --e-global-color-primary );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-profile-info .elementskit-author-des{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-ec51179-font-family ), Sans-serif;font-size:var( --e-global-typography-ec51179-font-size );font-weight:var( --e-global-typography-ec51179-font-weight );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-commentor-bio{margin:0px 0px 0px 0px;bottom:-100px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-commentor-bio .elementskit-commentor-image > img{height:70px;width:70px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .ekit-testimonial--avatar{margin:0px 0px 5px 0px;}
    .elementor-366 .elementor-element.elementor-element-0b01fa8 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;--ekit_testimonial_slidetoshow:1;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider{padding:3em 3em 3em 3em;box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0);}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider{border-radius:50px 50px 50px 0px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider{background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{color:var( --e-global-color-7e6a25d );margin:0em 0em 1em 0em;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-family:var( --e-global-typography-6185b3a-font-family ), Sans-serif;font-size:var( --e-global-typography-6185b3a-font-size );font-weight:var( --e-global-typography-6185b3a-font-weight );font-style:var( --e-global-typography-6185b3a-font-style );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider .elementskit-watermark-icon > i{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-watermark-icon > i{font-size:55px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-profile-info .elementskit-author-name{color:var( --e-global-color-primary );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );margin-bottom:0px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-profile-info .elementskit-author-des{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-ec51179-font-family ), Sans-serif;font-size:var( --e-global-typography-ec51179-font-size );font-weight:var( --e-global-typography-ec51179-font-weight );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-commentor-bio{margin:0px 0px 0px 0px;bottom:-100px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-commentor-bio .elementskit-commentor-image > img{height:70px;width:70px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .ekit-testimonial--avatar{margin:0px 0px 5px 0px;}
    .elementor-366 .elementor-element.elementor-element-09e9c8e:not(.elementor-motion-effects-element-type-background){background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-09e9c8e{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;padding:6em 1em 6em 1em;}
    .elementor-366 .elementor-element.elementor-element-e8b68e7.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-e8b68e7 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-20e1bf8{text-align:left;}
    .elementor-366 .elementor-element.elementor-element-20e1bf8 .elementor-heading-title{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-a6f85b6-font-family ), Sans-serif;font-size:var( --e-global-typography-a6f85b6-font-size );font-weight:var( --e-global-typography-a6f85b6-font-weight );font-style:var( --e-global-typography-a6f85b6-font-style );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-13a997b .elementor-heading-title{color:var( --e-global-color-accent );}
    .elementor-366 .elementor-element.elementor-element-13a997b > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-fe10ed9{text-align:left;color:var( --e-global-color-7e6a25d );}
    .elementor-366 .elementor-element.elementor-element-affb7c8.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-affb7c8 > .elementor-element-populated{margin:0em 0em 0em 1em;--e-column-margin-right:0em;--e-column-margin-left:1em;padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-b5c4f88.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-b5c4f88 > .elementor-element-populated{padding:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-4f02234 img{width:75%;}
    .elementor-366 .elementor-element.elementor-element-c99e5c6.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-c99e5c6 > .elementor-element-populated{padding:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-6296fdc img{width:75%;filter:brightness( 200% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );transition-duration:0.5s;}
    .elementor-366 .elementor-element.elementor-element-6296fdc:hover img{filter:brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );}
    .elementor-366 .elementor-element.elementor-element-80ce5c5.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-80ce5c5 > .elementor-element-populated{padding:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-eeb3161 img{width:75%;filter:brightness( 200% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );transition-duration:0.5s;}
    .elementor-366 .elementor-element.elementor-element-eeb3161:hover img{filter:brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );}
    .elementor-366 .elementor-element.elementor-element-b4a2e3b{margin-top:1em;margin-bottom:0em;}
    .elementor-366 .elementor-element.elementor-element-2a643ee.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-2a643ee > .elementor-element-populated{padding:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-b5ce625 img{width:75%;filter:brightness( 200% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );transition-duration:0.5s;}
    .elementor-366 .elementor-element.elementor-element-b5ce625:hover img{filter:brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );}
    .elementor-366 .elementor-element.elementor-element-cc601aa.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-cc601aa > .elementor-element-populated{padding:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-af37bba img{width:75%;filter:brightness( 200% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );transition-duration:0.5s;}
    .elementor-366 .elementor-element.elementor-element-af37bba:hover img{filter:brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );}
    .elementor-366 .elementor-element.elementor-element-e051730.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated{align-content:center;align-items:center;}
    .elementor-366 .elementor-element.elementor-element-e051730 > .elementor-element-populated{padding:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-fe87956 img{width:75%;filter:brightness( 200% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );transition-duration:0.5s;}
    .elementor-366 .elementor-element.elementor-element-fe87956:hover img{filter:brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );}
    .elementor-366 .elementor-element.elementor-element-14b64e1{overflow:hidden;padding:6em 1em 6em 1em;}
    .elementor-366 .elementor-element.elementor-element-d200a07 > .elementor-element-populated{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-d3d08dd{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-d3d08dd .elementor-heading-title{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-a6f85b6-font-family ), Sans-serif;font-size:var( --e-global-typography-a6f85b6-font-size );font-weight:var( --e-global-typography-a6f85b6-font-weight );font-style:var( --e-global-typography-a6f85b6-font-style );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-53c8bc4{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-53c8bc4 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-d85c41f{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-d85c41f > .elementor-widget-container{margin:0em 15em 0em 15em;}
    .elementor-366 .elementor-element.elementor-element-cd37e4b:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap{background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-cd37e4b > .elementor-element-populated{border-radius:50px 0px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-cd37e4b > .elementor-element-populated{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:0em 0em 2em 0em;}
    .elementor-366 .elementor-element.elementor-element-088c088 .elementor-repeater-item-07de403 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-088c088 .elementor-repeater-item-07de403 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-088c088 .elementor-repeater-item-c70514b > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-088c088 .elementor-repeater-item-c70514b > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-088c088 .elementor-repeater-item-c69baf7 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-088c088 .elementor-repeater-item-c69baf7 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-088c088 .profile-image-card:hover,.elementor-366 .elementor-element.elementor-element-088c088 .profile-image-card::before{background-color:transparent;background-image:linear-gradient(180deg, var( --e-global-color-63f36c5 ) 50%, var( --e-global-color-25b493e ) 100%);}
    .elementor-366 .elementor-element.elementor-element-088c088 .profile-body{padding:12em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-088c088 .profile-image-card{border-radius:50px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-088c088 .ekit-team-social-list > li > a{text-align:center;text-decoration:none;border-radius:50% 50% 50% 50%;}
    .elementor-366 .elementor-element.elementor-element-088c088 .ekit-team-social-list > li{display:inline-block;}
    .elementor-366 .elementor-element.elementor-element-91eb1fa{--icon-box-icon-margin:15px;}
    .elementor-366 .elementor-element.elementor-element-91eb1fa .elementor-icon-box-title{margin-bottom:5px;color:var( --e-global-color-accent );}
    .elementor-366 .elementor-element.elementor-element-91eb1fa .elementor-icon-box-description{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-67726a4:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap{background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-67726a4 > .elementor-element-populated{border-radius:50px 50px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-67726a4 > .elementor-element-populated{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:0em 0em 2em 0em;}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .elementor-repeater-item-07de403 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .elementor-repeater-item-07de403 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .elementor-repeater-item-c70514b > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .elementor-repeater-item-c70514b > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .elementor-repeater-item-c69baf7 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .elementor-repeater-item-c69baf7 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .profile-image-card:hover,.elementor-366 .elementor-element.elementor-element-ab4e965 .profile-image-card::before{background-color:transparent;background-image:linear-gradient(180deg, var( --e-global-color-63f36c5 ) 50%, var( --e-global-color-25b493e ) 100%);}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .profile-body{padding:12em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .profile-image-card{border-radius:50px 50px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .ekit-team-social-list > li > a{text-align:center;text-decoration:none;border-radius:50% 50% 50% 50%;}
    .elementor-366 .elementor-element.elementor-element-ab4e965 .ekit-team-social-list > li{display:inline-block;}
    .elementor-366 .elementor-element.elementor-element-a3e372d{--icon-box-icon-margin:15px;}
    .elementor-366 .elementor-element.elementor-element-a3e372d .elementor-icon-box-title{margin-bottom:5px;color:var( --e-global-color-accent );}
    .elementor-366 .elementor-element.elementor-element-a3e372d .elementor-icon-box-description{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-1d42053:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap{background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-1d42053 > .elementor-element-populated{border-radius:50px 50px 50px 50px;}
    .elementor-366 .elementor-element.elementor-element-1d42053 > .elementor-element-populated{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:0em 0em 2em 0em;}
    .elementor-366 .elementor-element.elementor-element-014850a .elementor-repeater-item-07de403 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-014850a .elementor-repeater-item-07de403 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-014850a .elementor-repeater-item-c70514b > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-014850a .elementor-repeater-item-c70514b > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-014850a .elementor-repeater-item-c69baf7 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-014850a .elementor-repeater-item-c69baf7 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-014850a .profile-image-card:hover,.elementor-366 .elementor-element.elementor-element-014850a .profile-image-card::before{background-color:transparent;background-image:linear-gradient(180deg, var( --e-global-color-63f36c5 ) 50%, var( --e-global-color-25b493e ) 100%);}
    .elementor-366 .elementor-element.elementor-element-014850a .profile-body{padding:12em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-014850a .profile-image-card{border-radius:50px 50px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-014850a .ekit-team-social-list > li > a{text-align:center;text-decoration:none;border-radius:50% 50% 50% 50%;}
    .elementor-366 .elementor-element.elementor-element-014850a .ekit-team-social-list > li{display:inline-block;}
    .elementor-366 .elementor-element.elementor-element-a415c77{--icon-box-icon-margin:15px;}
    .elementor-366 .elementor-element.elementor-element-a415c77 .elementor-icon-box-title{margin-bottom:5px;color:var( --e-global-color-accent );}
    .elementor-366 .elementor-element.elementor-element-a415c77 .elementor-icon-box-description{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-48ea638:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap{background-color:var( --e-global-color-25b493e );}
    .elementor-366 .elementor-element.elementor-element-48ea638 > .elementor-element-populated{border-radius:50px 50px 50px 0px;}
    .elementor-366 .elementor-element.elementor-element-48ea638 > .elementor-element-populated{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:0em 0em 2em 0em;}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .elementor-repeater-item-07de403 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .elementor-repeater-item-07de403 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .elementor-repeater-item-c70514b > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .elementor-repeater-item-c70514b > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .elementor-repeater-item-c69baf7 > a{color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .elementor-repeater-item-c69baf7 > a:hover{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .profile-image-card:hover,.elementor-366 .elementor-element.elementor-element-0aea2a1 .profile-image-card::before{background-color:transparent;background-image:linear-gradient(180deg, var( --e-global-color-63f36c5 ) 50%, var( --e-global-color-25b493e ) 100%);}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .profile-body{padding:12em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .profile-image-card{border-radius:50px 50px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .ekit-team-social-list > li > a{text-align:center;text-decoration:none;border-radius:50% 50% 50% 50%;}
    .elementor-366 .elementor-element.elementor-element-0aea2a1 .ekit-team-social-list > li{display:inline-block;}
    .elementor-366 .elementor-element.elementor-element-c83ac91{--icon-box-icon-margin:15px;}
    .elementor-366 .elementor-element.elementor-element-c83ac91 .elementor-icon-box-title{margin-bottom:5px;color:var( --e-global-color-accent );}
    .elementor-366 .elementor-element.elementor-element-c83ac91 .elementor-icon-box-description{color:var( --e-global-color-b0a5f9a );}
    .elementor-366 .elementor-element.elementor-element-159a322 .elementor-button:hover,.elementor-366 .elementor-element.elementor-element-159a322 .elementor-button:focus{color:var( --e-global-color-primary );background-color:var( --e-global-color-63f36c5 );}
    .elementor-366 .elementor-element.elementor-element-159a322 > .elementor-widget-container{margin:2em 0em 0em 0em;}
    @media (max-width:1024px){
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title span:last-child,.elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title > span{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-f7aafba > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-296d0d3 .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e563791{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-1f5d813 > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-ad3384c > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-8031c76 > .elementor-element-populated{margin:0em 4em 0em 0em;--e-column-margin-right:4em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-ef4e20b > .elementor-widget-container{margin:-13em -4em 0em 18em;}
    .elementor-366 .elementor-element.elementor-element-4ce8b0b > .elementor-element-populated{margin:2em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-7ebb03b .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .skill-title{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .number-percentage-wraper{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .skill-title{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .number-percentage-wraper{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .skill-title{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .number-percentage-wraper{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;--ekit_testimonial_slidetoshow:1;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-profile-info .elementskit-author-name{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-profile-info .elementskit-author-des{font-size:var( --e-global-typography-ec51179-font-size );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;--ekit_testimonial_slidetoshow:1;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-profile-info .elementskit-author-name{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-profile-info .elementskit-author-des{font-size:var( --e-global-typography-ec51179-font-size );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-0b01fa8 > .elementor-element-populated{margin:-1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;--ekit_testimonial_slidetoshow:1;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-profile-info .elementskit-author-name{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-profile-info .elementskit-author-des{font-size:var( --e-global-typography-ec51179-font-size );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-20e1bf8{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-20e1bf8 .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-13a997b{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-fe10ed9{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-fe10ed9 > .elementor-widget-container{margin:0em 4em 0em 4em;}
    .elementor-366 .elementor-element.elementor-element-affb7c8 > .elementor-element-populated{margin:0em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-4f02234{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-6296fdc{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-eeb3161{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-b5ce625{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-af37bba{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-fe87956{text-align:center;}
    .elementor-366 .elementor-element.elementor-element-d3d08dd .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-d85c41f > .elementor-widget-container{margin:0em 5em 0em 5em;}
    }
    @media (max-width:767px){
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title span:last-child,.elementor-366 .elementor-element.elementor-element-02cb850 .elementskit-section-title-wraper .elementskit-section-title > span{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-296d0d3 .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d538ab > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-e563791{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e563791 > .elementor-widget-container{margin:2em 0em 0em 0em;border-radius:40px 0px 0px 0px;}
    .elementor-366 .elementor-element.elementor-element-19ccd47 > .elementor-element-populated{margin:0em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-b601bcd img{border-radius:40px 0px 40px 40px;}
    .elementor-366 .elementor-element.elementor-element-b601bcd > .elementor-widget-container{margin:0em 0em 0em 2em;}
    .elementor-366 .elementor-element.elementor-element-bdc7a82{--spacer-size:250px;}
    .elementor-366 .elementor-element.elementor-element-bdc7a82 > .elementor-widget-container{margin:-16em 2em 0em 1em;border-radius:40px 0px 40px 40px;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact .elementskit-funfact-inner{padding:1em 1em 1em 1em;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef .elementskit-funfact{border-radius:40px 40px 40px 0px;}
    .elementor-366 .elementor-element.elementor-element-c2a22ef > .elementor-widget-container{margin:-6.5em 4em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact-icon{font-size:60px;}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-dba9315 .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact-icon{font-size:60px;}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-e068023 .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-1f5d813 > .elementor-element-populated{margin:0em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact-icon{font-size:60px;}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-d1657e5 .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-ad3384c > .elementor-element-populated{margin:0em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact-icon{font-size:60px;}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .funfact-title{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-08090ce .elementskit-funfact .super{font-size:var( --e-global-typography-58cfe92-font-size );line-height:var( --e-global-typography-58cfe92-line-height );letter-spacing:var( --e-global-typography-58cfe92-letter-spacing );word-spacing:var( --e-global-typography-58cfe92-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-8031c76 > .elementor-element-populated{margin:0em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-4df6451 img{border-radius:0px 40px 40px 40px;}
    .elementor-366 .elementor-element.elementor-element-4df6451 > .elementor-widget-container{margin:0em 2em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-f924706{--spacer-size:250px;}
    .elementor-366 .elementor-element.elementor-element-f924706 > .elementor-widget-container{margin:-16em 1em 0em 2em;border-radius:40px 40px 40px 40px;}
    .elementor-366 .elementor-element.elementor-element-ef4e20b img{border-radius:40px 40px 0px 40px;}
    .elementor-366 .elementor-element.elementor-element-ef4e20b > .elementor-widget-container{margin:-7em 0em 0em 7em;}
    .elementor-366 .elementor-element.elementor-element-4ce8b0b > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-7ebb03b .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-7ae4dcd > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .skill-title{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-79e171d .skillbar-group .number-percentage-wraper{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .skill-title{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-27373b4 .skillbar-group .number-percentage-wraper{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .skill-title{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-c9efd72 .skillbar-group .number-percentage-wraper{font-size:var( --e-global-typography-734265e-font-size );line-height:var( --e-global-typography-734265e-line-height );letter-spacing:var( --e-global-typography-734265e-letter-spacing );word-spacing:var( --e-global-typography-734265e-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider{padding:2em 2em 2em 2em;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider{border-radius:40px 0px 40px 40px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-watermark-icon > i{font-size:50px;}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-profile-info .elementskit-author-name{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-bed7e08 .elementskit-profile-info .elementskit-author-des{font-size:var( --e-global-typography-ec51179-font-size );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-00a37b1 > .elementor-element-populated{margin:-1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider{padding:2em 2em 2em 2em;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider{border-radius:40px 40px 40px 40px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-watermark-icon > i{font-size:50px;}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-profile-info .elementskit-author-name{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-4d2b59e .elementskit-profile-info .elementskit-author-des{font-size:var( --e-global-typography-ec51179-font-size );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-0b01fa8 > .elementor-element-populated{margin:-1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-testimonial-slider{--ekit_testimonial_left_right_spacing:0px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider{padding:2em 2em 2em 2em;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider{border-radius:40px 40px 40px 0px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-single-testimonial-slider  .elementskit-commentor-content > p{font-size:var( --e-global-typography-6185b3a-font-size );line-height:var( --e-global-typography-6185b3a-line-height );letter-spacing:var( --e-global-typography-6185b3a-letter-spacing );word-spacing:var( --e-global-typography-6185b3a-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-watermark-icon > i{font-size:50px;}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-profile-info .elementskit-author-name{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-a3e983c .elementskit-profile-info .elementskit-author-des{font-size:var( --e-global-typography-ec51179-font-size );line-height:var( --e-global-typography-ec51179-line-height );letter-spacing:var( --e-global-typography-ec51179-letter-spacing );word-spacing:var( --e-global-typography-ec51179-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-20e1bf8 .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-fe10ed9 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-366 .elementor-element.elementor-element-4f02234 img{width:75%;}
    .elementor-366 .elementor-element.elementor-element-c99e5c6 > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-6296fdc img{width:75%;}
    .elementor-366 .elementor-element.elementor-element-80ce5c5 > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-eeb3161 img{width:75%;}
    .elementor-366 .elementor-element.elementor-element-b5ce625 img{width:75%;}
    .elementor-366 .elementor-element.elementor-element-cc601aa > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-af37bba img{width:75%;}
    .elementor-366 .elementor-element.elementor-element-e051730 > .elementor-element-populated{margin:1em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;}
    .elementor-366 .elementor-element.elementor-element-fe87956 img{width:75%;}
    .elementor-366 .elementor-element.elementor-element-d3d08dd .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-366 .elementor-element.elementor-element-d85c41f > .elementor-widget-container{margin:0em 0em 0em 0em;}
    }
    @media (min-width:768px){
    .elementor-366 .elementor-element.elementor-element-609a14d{width:54%;}
    .elementor-366 .elementor-element.elementor-element-4d538ab{width:46%;}
    .elementor-366 .elementor-element.elementor-element-e8b68e7{width:40%;}
    .elementor-366 .elementor-element.elementor-element-affb7c8{width:60%;}
    }
    @media (max-width:1024px) and (min-width:768px){
    .elementor-366 .elementor-element.elementor-element-f7aafba{width:100%;}
    .elementor-366 .elementor-element.elementor-element-19ccd47{width:100%;}
    .elementor-366 .elementor-element.elementor-element-c3d4d04{width:50%;}
    .elementor-366 .elementor-element.elementor-element-8516033{width:50%;}
    .elementor-366 .elementor-element.elementor-element-1f5d813{width:50%;}
    .elementor-366 .elementor-element.elementor-element-ad3384c{width:50%;}
    .elementor-366 .elementor-element.elementor-element-8031c76{width:100%;}
    .elementor-366 .elementor-element.elementor-element-4ce8b0b{width:100%;}
    .elementor-366 .elementor-element.elementor-element-35281f4{width:50%;}
    .elementor-366 .elementor-element.elementor-element-00a37b1{width:50%;}
    .elementor-366 .elementor-element.elementor-element-0b01fa8{width:100%;}
    .elementor-366 .elementor-element.elementor-element-e8b68e7{width:100%;}
    .elementor-366 .elementor-element.elementor-element-affb7c8{width:100%;}
    .elementor-366 .elementor-element.elementor-element-cd37e4b{width:50%;}
    .elementor-366 .elementor-element.elementor-element-67726a4{width:50%;}
    .elementor-366 .elementor-element.elementor-element-1d42053{width:50%;}
    .elementor-366 .elementor-element.elementor-element-48ea638{width:50%;}
    }
    @media (min-width:1025px){
    .elementor-366 .elementor-element.elementor-element-b57e4b3:not(.elementor-motion-effects-element-type-background){background-attachment:fixed;}
    }
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/css/all.min.css?ver=3.15.1 ; media=all */
    @media all{
    .fab,.fas{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
    .fa-chevron-right:before{content:"\f054";}
    .fa-instagram:before{content:"\f16d";}
    .fa-twitter:before{content:"\f099";}
    .fab{font-family:"Font Awesome 5 Brands";}
    .fab{font-weight:400;}
    .fas{font-family:"Font Awesome 5 Free";}
    .fas{font-weight:900;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementskit-lite/widgets/init/assets/css/widget-styles.css?ver=2.9.0 ; media=all */
    @media all{
    :focus{outline:0;}
    .ekit-wid-con a,.ekit-wid-con div,.ekit-wid-con li,.ekit-wid-con ul{-webkit-tap-highlight-color:transparent;-webkit-tap-highlight-color:transparent;}
    .ekit-wid-con a:active,.ekit-wid-con a:focus{-moz-outline:none;outline:0;}
    .ekit-wid-con img:not([draggable]){max-width:100%;height:auto;}
    .ekit-wid-con a{text-decoration:none;outline:0;}
    .ekit-wid-con a:active,.ekit-wid-con a:focus,.ekit-wid-con a:hover,.ekit-wid-con a:visited{text-decoration:none;outline:0;}
    .ekit-wid-con img{border:none;max-width:100%;}
    .ekit-wid-con li,.ekit-wid-con ul{margin:0;padding:0;}
    .ekit-wid-con p{margin-bottom:10px;}
    .ekit-wid-con .text-center{text-align:center!important;}
    .ekit-wid-con .text-center{text-align:center!important;}
    .ekit-wid-con .swiper.ekit-main-swiper{position:static;}
    .ekit-wid-con img{-webkit-user-select:none;-moz-user-select:none;-o-user-select:none;-ms-user-select:none;user-select:none;}
    .elementor-widget-container .ekit-wid-con a{text-decoration:none;}
    .ekit-wid-con .profile-body{padding-bottom:20px;}
    .ekit-wid-con .profile-body .profile-title{font-size:22px;color:#000;margin:0;}
    .ekit-wid-con .profile-body .profile-designation{color:rgba(0,0,0,.5);font-size:13px;-webkit-transition:all .4s ease;transition:all .4s ease;margin-bottom:15px;}
    .ekit-wid-con .profile-image-card{position:relative;overflow:hidden;}
    .ekit-wid-con .profile-image-card::before{position:absolute;content:"";top:100%;left:0;height:100%;width:100%;background-color:rgba(0,0,0,.7);-webkit-transition:all .4s ease .2s;transition:all .4s ease .2s;z-index:1;-webkit-backface-visibility:hidden;backface-visibility:hidden;opacity:0;visibility:hidden;}
    .ekit-wid-con .profile-image-card .hover-area{position:absolute;top:50%;-webkit-transform:translateY(-20%);transform:translateY(-20%);z-index:2;width:100%;opacity:0;visibility:hidden;-webkit-transition:all .3s ease;transition:all .3s ease;}
    .ekit-wid-con .profile-image-card .hover-area .profile-title{color:#fff;}
    .ekit-wid-con .profile-image-card .hover-area .profile-designation{color:#fff;}
    .ekit-wid-con .profile-image-card:hover .hover-area{opacity:1;visibility:visible;-webkit-transform:translateY(-50%);transform:translateY(-50%);-webkit-transition:all .3s ease .1s;transition:all .3s ease .1s;}
    .ekit-wid-con .profile-image-card:hover::before{top:0;opacity:1;visibility:visible;-webkit-transition:all .4s ease 0s;transition:all .4s ease 0s;}
    .ekit-wid-con .ekit-team-style-overlay>img{display:block;}
    .ekit-wid-con .ekit-team-img>img{margin-left:auto;margin-right:auto;border-radius:inherit;}
    .ekit-wid-con .ekit-team-img.profile-image-card>img{width:100%;}
    .ekit-wid-con .ekit-team-social-list{list-style:none;}
    .ekit-wid-con .ekit-team-social-list>li{display:inline-block;}
    .ekit-wid-con .ekit-team-social-list>li:not(:last-child){margin-right:10px;}
    .ekit-wid-con .ekit-team-social-list>li>a{display:inline-block;color:#fff;width:30px;height:30px;line-height:32px;font-size:12px;background-color:#000;text-align:center;-webkit-transition:all .3s;transition:all .3s;}
    .ekit-wid-con .ekit-team-social-list>li>a:hover{background-color:rgba(0,0,0,.8);border-radius:50%;}
    .ekit-wid-con .ekit-team-social-list>li>a:hover>i::before{-webkit-animation:iconTranslateY .4s forwards;animation:iconTranslateY .4s forwards;}
    .ekit-wid-con .ekit-team-social-list>li>a>i{overflow:hidden;}
    .ekit-wid-con .ekit-team-social-list>li>a>i::before{display:inline-block;-webkit-backface-visibility:hidden;backface-visibility:hidden;}
    .ekit-wid-con .skill-bar{height:3px;background-color:#f5f5f5;}
    .ekit-wid-con .skill-bar .skill-track{width:0%;background-color:#333;display:block;height:100%;overflow:visible!important;position:relative;}
    .ekit-wid-con .skill-bar .skill-track .number-percentage-wraper{position:absolute;right:0;top:-33px;z-index:1;}
    .ekit-wid-con .skill-bar-content{margin-bottom:7px;}
    .ekit-wid-con .skill-bar-content .skill-title{font-weight:700;color:#000;}
    .ekit-wid-con .number-percentage-wraper{color:#000;}
    .ekit-wid-con .tooltip-style .single-skill-bar .number-percentage-wraper{color:#fff;height:30px;padding:0 10px;z-index:1;text-align:center;right:calc(0% - 22px);top:-30px;}
    .ekit-wid-con .tooltip-style .single-skill-bar .number-percentage-wraper .svg-content{position:absolute;top:0;left:0;z-index:-1;fill:#333;height:100%;width:100%;}
    .ekit-wid-con .elementskit-funfact{-webkit-transition:all .4s ease;transition:all .4s ease;-webkit-backface-visibility:hidden;backface-visibility:hidden;position:relative;z-index:2;background-repeat:no-repeat;background-size:cover;background-position:center center;}
    .ekit-wid-con .elementskit-funfact .funfact-content{line-height:1;}
    .ekit-wid-con .elementskit-funfact .funfact-content .number-percentage-wraper{font-size:28px;font-weight:700;color:#333;}
    .ekit-wid-con .elementskit-funfact .elementskit-funfact-icon{display:inline-block;}
    .ekit-wid-con .elementskit-funfact .funfact-title{color:gray;font-size:15px;margin:0;font-weight:400;}
    .ekit-wid-con .elementskit-funfact .super{font-size:22px;color:#2575fc;vertical-align:super;position:relative;top:-5px;}
    .ekit-wid-con .elementskit-single-testimonial-slider{background-color:#fff;-webkit-box-shadow:9px 11px 14px 0 rgba(0,0,0,.1);box-shadow:9px 11px 14px 0 rgba(0,0,0,.1);padding:30px;padding-left:60px;position:relative;-webkit-transition:all .4s ease;transition:all .4s ease;z-index:1;}
    .ekit-wid-con .elementskit-single-testimonial-slider .elementskit-commentor-content>p{-webkit-transition:all .4s ease;transition:all .4s ease;}
    .ekit-wid-con .elementskit-single-testimonial-slider:before{position:absolute;content:"";top:0;left:0;height:100%;width:100%;border-radius:inherit;-webkit-transform-origin:bottom;transform-origin:bottom;-webkit-transform:scaleY(0);transform:scaleY(0);-webkit-transition:all .4s ease;transition:all .4s ease;z-index:-1;}
    .ekit-wid-con .elementskit-single-testimonial-slider:hover::before{-webkit-transform:scaleY(1);transform:scaleY(1);}
    .ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon{position:static;color:rgba(0,0,0,.1);line-height:1;display:inline-block;}
    .ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon>i{font-size:48px;-webkit-transition:all .4s ease;transition:all .4s ease;}
    .ekit-wid-con .elementskit-commentor-content{padding-right:30px;position:relative;}
    .ekit-wid-con .elementskit-commentor-content>p{margin-bottom:35px;color:rgba(0,0,0,.5);}
    .ekit-wid-con .elementskit-commentor-bio .elementskit-commentor-image>img{border-radius:50%;height:70px;width:70px;}
    .ekit-wid-con .elementskit-commentor-bio .elementskit-author-name{font-size:22px;line-height:1;}
    .ekit-wid-con .elementskit-commentor-bio .elementskit-author-des{font-size:13px;}
    .ekit-wid-con .elementskit-profile-info .elementskit-author-name{display:block;font-size:18px;font-weight:700;color:#000;-webkit-transition:color .3s;transition:color .3s;}
    .ekit-wid-con .elementskit-profile-info .elementskit-author-des{color:rgba(0,0,0,.5);font-weight:400;}
    .ekit-wid-con .elementskit-testimonial-slider .swiper-wrapper{overflow:visible;}
    .ekit-wid-con .elementskit-commentor-bio{position:absolute;left:0;width:100%;}
    .ekit-wid-con .ekit-testimonia-style-4{text-align:center;}
    .ekit-wid-con .ekit-testimonia-style-4 .elementskit-single-testimonial-slider{padding:30px;padding-bottom:50px;margin-bottom:80px;}
    .ekit-wid-con .ekit-testimonia-style-4 .elementskit-single-testimonial-slider .elementskit-commentor-content{padding-right:0;}
    .ekit-wid-con .ekit-testimonia-style-4 .elementskit-single-testimonial-slider .elementskit-commentor-image img{margin:auto;}
    .ekit-wid-con .ekit-testimonia-style-4 .elementskit-watermark-icon{margin-bottom:23px;color:rgba(37,117,252,.2);}
    .ekit-wid-con .ekit-testimonia-style-4 .ekit-testimonial--avatar{margin-bottom:20px;}
    .ekit-wid-con .ekit_watermark_icon_custom_position{position:absolute;}
    .ekit-wid-con .ekit-heading{position:relative;}
    .ekit-wid-con .elementskit-section-title{-webkit-transition:all .3s ease;transition:all .3s ease;margin:0;margin-bottom:20px;}
    .ekit-wid-con .elementskit-section-title{font-weight:500;}
    .ekit-wid-con .elementskit-section-title span{-webkit-transition:all .3s ease;transition:all .3s ease;display:inline-block;font-weight:700;}
    .ekit-wid-con .elementskit-section-title-wraper.text_center{text-align:center;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementskit-lite/widgets/init/assets/css/responsive.css?ver=2.9.0 ; media=all */
    @media all{
    @media (min-width:992px) and (max-width:1199px){
    .ekit-wid-con .elementskit-single-testimonial-slider{-webkit-box-shadow:0 10px 10px 0 rgba(0,0,0,.1);box-shadow:0 10px 10px 0 rgba(0,0,0,.1);}
    }
    @media (max-width:991px){
    .ekit-wid-con .elementskit-single-testimonial-slider{-webkit-box-shadow:0 10px 10px 0 rgba(0,0,0,.1);box-shadow:0 10px 10px 0 rgba(0,0,0,.1);padding:30px;}
    .ekit-wid-con .elementskit-commentor-content{padding-right:0;margin-bottom:30px;}
    }
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3 ; media=all */
    @media all{
    .fab,.fas{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
    .fa-chevron-right:before{content:"\f054";}
    .fa-instagram:before{content:"\f16d";}
    .fa-twitter:before{content:"\f099";}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3 ; media=all */
    @media all{
    .fas{font-family:"Font Awesome 5 Free";font-weight:900;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.min.css?ver=5.15.3 ; media=all */
    @media all{
    .fab{font-family:"Font Awesome 5 Brands";font-weight:400;}
    }
    /*! CSS Used from: Embedded */
    .elementor-widget-image{text-align:center;}
    .elementor-widget-image img{vertical-align:middle;display:inline-block;}
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/css/widget-icon-box.min.css */
    .elementor-widget-icon-box .elementor-icon-box-wrapper{display:block;text-align:center;}
    .elementor-widget-icon-box .elementor-icon-box-icon{margin-bottom:var(--icon-box-icon-margin,15px);margin-right:auto;margin-left:auto;}
    @media (min-width:768px){
    .elementor-widget-icon-box.elementor-vertical-align-top .elementor-icon-box-wrapper{align-items:flex-start;}
    }
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper{display:flex;}
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon{display:inline-flex;flex:0 0 auto;}
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper{text-align:left;flex-direction:row;}
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon{margin-right:var(--icon-box-icon-margin,15px);margin-left:0;margin-bottom:unset;}
    @media (max-width:767px){
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper{display:flex;}
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-icon{display:inline-flex;flex:0 0 auto;}
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper{text-align:left;flex-direction:row;}
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-icon{margin-right:var(--icon-box-icon-margin,15px);margin-left:0;margin-bottom:unset;}
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon{display:block;flex:unset;}
    }
    .elementor-widget-icon-box .elementor-icon-box-content{flex-grow:1;}
    .elementor-widget-icon-box .elementor-icon-box-description{margin:0;}
    /*! CSS Used from: Embedded */
    .elementor-heading-title{padding:0;margin:0;line-height:1;}
    /*! CSS Used from: Embedded */
    .elementor-column .elementor-spacer-inner{height:var(--spacer-size);}
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css */
    .elementor-widget .elementor-icon-list-items{list-style-type:none;margin:0;padding:0;}
    .elementor-widget .elementor-icon-list-item{margin:0;padding:0;position:relative;}
    .elementor-widget .elementor-icon-list-item:after{position:absolute;bottom:0;width:100%;}
    .elementor-widget .elementor-icon-list-item{display:flex;font-size:inherit;align-items:var(--icon-vertical-align,center);}
    .elementor-widget .elementor-icon-list-icon+.elementor-icon-list-text{align-self:center;padding-left:5px;}
    .elementor-widget .elementor-icon-list-icon{display:flex;position:relative;top:var(--icon-vertical-offset,initial);}
    .elementor-widget .elementor-icon-list-icon i{width:1.25em;font-size:var(--e-icon-list-icon-size);}
    .elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon{text-align:var(--e-icon-list-icon-align);}
    .elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after{right:0;}
    @media (min-width:-1){
    .elementor-widget:not(.elementor-widescreen-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-widescreen-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:-1){
    .elementor-widget:not(.elementor-laptop-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-laptop-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:-1){
    .elementor-widget:not(.elementor-tablet_extra-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-tablet_extra-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:1024px){
    .elementor-widget:not(.elementor-tablet-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-tablet-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:-1){
    .elementor-widget:not(.elementor-mobile_extra-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-mobile_extra-align-left) .elementor-icon-list-item:after{right:0;}
    }
    @media (max-width:767px){
    .elementor-widget:not(.elementor-mobile-align-right) .elementor-icon-list-item:after{left:0;}
    .elementor-widget:not(.elementor-mobile-align-left) .elementor-icon-list-item:after{right:0;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=3.15.1 ; media=all */
    @media all{
    .fadeIn{animation-name:fadeIn;}
    .fadeInLeft{animation-name:fadeInLeft;}
    .fadeInRight{animation-name:fadeInRight;}
    .fadeInUp{animation-name:fadeInUp;}
    }
    /*! CSS Used keyframes */
    @-webkit-keyframes iconTranslateY{49%{-webkit-transform:translateY(100%);transform:translateY(100%);}50%{opacity:0;-webkit-transform:translateY(-100%);transform:translateY(-100%);}51%{opacity:1;}}
    @keyframes iconTranslateY{49%{-webkit-transform:translateY(100%);transform:translateY(100%);}50%{opacity:0;-webkit-transform:translateY(-100%);transform:translateY(-100%);}51%{opacity:1;}}
    @-webkit-keyframes fadeIn{from{opacity:0;}to{opacity:1;}}
    @keyframes fadeIn{from{opacity:0;}to{opacity:1;}}
    @keyframes fadeIn{from{opacity:0;}to{opacity:1;}}
    @-webkit-keyframes fadeInLeft{from{opacity:0;-webkit-transform:translateX(-100%);transform:translateX(-100%);}to{opacity:1;}}
    @keyframes fadeInLeft{from{opacity:0;-webkit-transform:translateX(-100%);transform:translateX(-100%);}to{opacity:1;}}
    @keyframes fadeInLeft{from{opacity:0;transform:translate3d(-100%,0,0);}to{opacity:1;transform:none;}}
    @-webkit-keyframes fadeInRight{from{opacity:0;-webkit-transform:translateX(100%);transform:translateX(100%);}to{opacity:1;}}
    @keyframes fadeInRight{from{opacity:0;-webkit-transform:translateX(100%);transform:translateX(100%);}to{opacity:1;}}
    @keyframes fadeInRight{from{opacity:0;transform:translate3d(100%,0,0);}to{opacity:1;transform:none;}}
    @keyframes fadeInUp{from{opacity:0;transform:translate3d(0,100%,0);}to{opacity:1;transform:none;}}
    /*! CSS Used fontfaces */
    @font-face{font-family:elementskit;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementskit-lite/modules/elementskit-icon-pack/assets/fonts/elementskit.woff?y24e1e) format("woff");font-weight:400;font-style:normal;font-display:swap;}
    @font-face{font-family:"Font Awesome 5 Brands";font-style:normal;font-weight:400;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.svg#fontawesome) format("svg");}
    @font-face{font-family:"Font Awesome 5 Brands";font-style:normal;font-weight:400;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-brands-400.svg#fontawesome) format("svg");}
    @font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:400;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.svg#fontawesome) format("svg");}
    @font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.svg#fontawesome) format("svg");}
    @font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.svg#fontawesome) format("svg");}