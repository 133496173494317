.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .text-overlay h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .text-overlay p {
    font-size: 18px;
  }

  .text-overlay1 {
    position: absolute;
    top: 10%;
    left: 10%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }