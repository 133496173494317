/*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-includes/css/dist/block-library/style.min.css?ver=6.2.2 ; media=all */
@media all{
    html :where(img[class*=wp-image-]){height:auto;max-width:100%;}
    figure{margin:0 0 1em;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/themes/hello-elementor/style.min.css?ver=2.8.1 ; media=all */
    @media all{
    *,:after,:before{box-sizing:border-box;}
    h2,h3,h4{-webkit-margin-before:.5rem;margin-block-start:.5rem;-webkit-margin-after:1rem;margin-block-end:1rem;font-family:inherit;font-weight:500;line-height:1.2;color:inherit;}
    h2{font-size:2rem;}
    h3{font-size:1.75rem;}
    h4{font-size:1.5rem;}
    p{-webkit-margin-before:0;margin-block-start:0;-webkit-margin-after:.9rem;margin-block-end:.9rem;}
    a{background-color:transparent;text-decoration:none;color:#c36;}
    a:active,a:hover{color:#336;}
    img{border-style:none;height:auto;max-width:100%;}
    @media print{
    *,:after,:before{background:transparent!important;color:#000!important;box-shadow:none!important;text-shadow:none!important;}
    a,a:visited{text-decoration:underline;}
    a[href]:after{content:" (" attr(href) ")";}
    a[href^="#"]:after{content:"";}
    img{-moz-column-break-inside:avoid;break-inside:avoid;}
    h2,h3,p{orphans:3;widows:3;}
    h2,h3{-moz-column-break-after:avoid;break-after:avoid;}
    }
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.15.1 ; media=all */
    @media all{
    .elementor *,.elementor :after,.elementor :before{box-sizing:border-box;}
    .elementor a{box-shadow:none;text-decoration:none;}
    .elementor img{height:auto;max-width:100%;border:none;border-radius:0;box-shadow:none;}
    .elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure{margin:0;}
    .elementor-element{--flex-direction:initial;--flex-wrap:initial;--justify-content:initial;--align-items:initial;--align-content:initial;--gap:initial;--flex-basis:initial;--flex-grow:initial;--flex-shrink:initial;--order:initial;--align-self:initial;flex-basis:var(--flex-basis);flex-grow:var(--flex-grow);flex-shrink:var(--flex-shrink);order:var(--order);align-self:var(--align-self);}
    .elementor-element:where(.e-con-full,.elementor-widget){flex-direction:var(--flex-direction);flex-wrap:var(--flex-wrap);justify-content:var(--justify-content);align-items:var(--align-items);align-content:var(--align-content);gap:var(--gap);}
    .elementor-align-center{text-align:center;}
    .elementor-align-center .elementor-button{width:auto;}
    .elementor-section{position:relative;}
    .elementor-section .elementor-container{display:flex;margin-right:auto;margin-left:auto;position:relative;}
    @media (max-width:1024px){
    .elementor-section .elementor-container{flex-wrap:wrap;}
    }
    .elementor-section.elementor-section-boxed>.elementor-container{max-width:1140px;}
    .elementor-widget-wrap{position:relative;width:100%;flex-wrap:wrap;align-content:flex-start;}
    .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap{display:flex;}
    .elementor-widget-wrap>.elementor-element{width:100%;}
    .elementor-widget{position:relative;}
    .elementor-widget:not(:last-child){margin-bottom:20px;}
    .elementor-column{position:relative;min-height:1px;display:flex;}
    .elementor-column-gap-default>.elementor-column>.elementor-element-populated{padding:10px;}
    @media (min-width:768px){
    .elementor-column.elementor-col-33{width:33.333%;}
    .elementor-column.elementor-col-100{width:100%;}
    }
    @media (max-width:767px){
    .elementor-column{width:100%;}
    }
    .elementor-element .elementor-widget-container{transition:background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);}
    .elementor-button{display:inline-block;line-height:1;background-color:#69727d;font-size:15px;padding:12px 24px;border-radius:3px;color:#fff;fill:#fff;text-align:center;transition:all .3s;}
    .elementor-button:focus,.elementor-button:hover,.elementor-button:visited{color:#fff;}
    .elementor-button-content-wrapper{display:flex;justify-content:center;}
    .elementor-button-icon{flex-grow:0;order:5;}
    .elementor-button-text{flex-grow:1;order:10;display:inline-block;}
    .elementor-button .elementor-align-icon-right{margin-left:5px;order:15;}
    .elementor-button span{text-decoration:inherit;}
    .elementor-element{--swiper-theme-color:#000;--swiper-navigation-size:44px;--swiper-pagination-bullet-size:6px;--swiper-pagination-bullet-horizontal-gap:6px;}
    .animated{animation-duration:1.25s;}
    .animated.animated-slow{animation-duration:2s;}
    @media (prefers-reduced-motion:reduce){
    .animated{animation:none;}
    }
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/elementor/css/post-3.css?ver=1678682998 ; media=all */
    @media all{
    .elementor-kit-3 a{color:var( --e-global-color-accent );}
    .elementor-kit-3 a:hover{color:var( --e-global-color-b0a5f9a );}
    .elementor-kit-3 h2{color:var( --e-global-color-primary );font-family:var( --e-global-typography-ba5b015-font-family ), Sans-serif;font-size:var( --e-global-typography-ba5b015-font-size );font-weight:var( --e-global-typography-ba5b015-font-weight );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-kit-3 h3{color:var( --e-global-color-primary );font-family:var( --e-global-typography-8c2b331-font-family ), Sans-serif;font-size:var( --e-global-typography-8c2b331-font-size );font-weight:var( --e-global-typography-8c2b331-font-weight );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-kit-3 h4{color:var( --e-global-color-primary );font-family:var( --e-global-typography-7110a2b-font-family ), Sans-serif;font-size:var( --e-global-typography-7110a2b-font-size );font-weight:var( --e-global-typography-7110a2b-font-weight );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-kit-3 .elementor-button{font-family:var( --e-global-typography-accent-font-family ), Sans-serif;font-size:var( --e-global-typography-accent-font-size );font-weight:var( --e-global-typography-accent-font-weight );line-height:var( --e-global-typography-accent-line-height );letter-spacing:var( --e-global-typography-accent-letter-spacing );color:var( --e-global-color-accent );background-color:var( --e-global-color-b0a5f9a );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );border-radius:20px 0px 20px 20px;padding:20px 20px 20px 20px;}
    .elementor-kit-3 .elementor-button:hover,.elementor-kit-3 .elementor-button:focus{color:var( --e-global-color-accent );background-color:var( --e-global-color-63f36c5 );border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );border-radius:20px 0px 20px 20px;}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1340px;}
    .elementor-widget:not(:last-child){margin-bottom:20px;}
    .elementor-element{--widgets-spacing:20px;}
    @media (max-width:1024px){
    .elementor-kit-3 h2{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-kit-3 h3{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-kit-3 h4{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-kit-3 .elementor-button{font-size:var( --e-global-typography-accent-font-size );line-height:var( --e-global-typography-accent-line-height );letter-spacing:var( --e-global-typography-accent-letter-spacing );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:1024px;}
    }
    @media (max-width:767px){
    .elementor-kit-3 h2{font-size:var( --e-global-typography-ba5b015-font-size );line-height:var( --e-global-typography-ba5b015-line-height );letter-spacing:var( --e-global-typography-ba5b015-letter-spacing );word-spacing:var( --e-global-typography-ba5b015-word-spacing );}
    .elementor-kit-3 h3{font-size:var( --e-global-typography-8c2b331-font-size );line-height:var( --e-global-typography-8c2b331-line-height );letter-spacing:var( --e-global-typography-8c2b331-letter-spacing );word-spacing:var( --e-global-typography-8c2b331-word-spacing );}
    .elementor-kit-3 h4{font-size:var( --e-global-typography-7110a2b-font-size );line-height:var( --e-global-typography-7110a2b-line-height );letter-spacing:var( --e-global-typography-7110a2b-letter-spacing );word-spacing:var( --e-global-typography-7110a2b-word-spacing );}
    .elementor-kit-3 .elementor-button{font-size:var( --e-global-typography-accent-font-size );line-height:var( --e-global-typography-accent-line-height );letter-spacing:var( --e-global-typography-accent-letter-spacing );}
    .elementor-section.elementor-section-boxed > .elementor-container{max-width:767px;}
    }
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/elementor/css/post-5.css?ver=1678734116 ; media=all */
    @media all{
    .elementor-5 .elementor-element.elementor-element-c5773ea{overflow:hidden;margin-top:-6em;margin-bottom:0em;padding:0em 0em 1em 0em;}
    .elementor-5 .elementor-element.elementor-element-597f45e:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap{background-color:var( --e-global-color-accent );}
    .elementor-5 .elementor-element.elementor-element-597f45e > .elementor-element-populated{border-radius:50px 50px 50px 50px;}
    .elementor-5 .elementor-element.elementor-element-597f45e > .elementor-element-populated{box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0.2);transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;padding:6em 6em 6em 6em;}
    .elementor-5 .elementor-element.elementor-element-d8ac617{text-align:center;}
    .elementor-5 .elementor-element.elementor-element-d8ac617 .elementor-heading-title{color:var( --e-global-color-b0a5f9a );font-family:var( --e-global-typography-a6f85b6-font-family ), Sans-serif;font-size:var( --e-global-typography-a6f85b6-font-size );font-weight:var( --e-global-typography-a6f85b6-font-weight );font-style:var( --e-global-typography-a6f85b6-font-style );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-5 .elementor-element.elementor-element-d283939{text-align:center;}
    .elementor-5 .elementor-element.elementor-element-d283939 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-5 .elementor-element.elementor-element-aa71512{text-align:center;}
    .elementor-5 .elementor-element.elementor-element-aa71512 > .elementor-widget-container{margin:0em 11em 0em 11em;}
    .elementor-5 .elementor-element.elementor-element-99cc390 > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-25b493e );transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:2em 2em 2em 2em;}
    .elementor-5 .elementor-element.elementor-element-99cc390 > .elementor-element-populated{border-radius:50px 0px 50px 50px;}
    .elementor-5 .elementor-element.elementor-element-99cc390:hover > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );}
    .elementor-5 .elementor-element.elementor-element-018377e.elementor-position-top .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-018377e .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    .elementor-5 .elementor-element.elementor-element-018377e .elementor-image-box-img img{transition-duration:0.3s;}
    .elementor-5 .elementor-element.elementor-element-018377e .elementor-image-box-title{margin-bottom:10px;}
    .elementor-5 .elementor-element.elementor-element-018377e > .elementor-widget-container{margin:0em 1em 0em 1em;}
    .elementor-5 .elementor-element.elementor-element-f6ebc0b .elementor-button{fill:var( --e-global-color-b0a5f9a );color:var( --e-global-color-b0a5f9a );background-color:var( --e-global-color-63f36c5 );border-style:none;padding:0px 0px 0px 0px;}
    .elementor-5 .elementor-element.elementor-element-f6ebc0b .elementor-button:hover,.elementor-5 .elementor-element.elementor-element-f6ebc0b .elementor-button:focus{color:var( --e-global-color-primary );background-color:var( --e-global-color-63f36c5 );}
    .elementor-5 .elementor-element.elementor-element-82ab4d9 > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:2em 2em 2em 2em;}
    .elementor-5 .elementor-element.elementor-element-82ab4d9 > .elementor-element-populated{border-radius:50px 50px 50px 50px;}
    .elementor-5 .elementor-element.elementor-element-82ab4d9:hover > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-25b493e );}
    .elementor-5 .elementor-element.elementor-element-3a03600.elementor-position-top .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-3a03600 .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    .elementor-5 .elementor-element.elementor-element-3a03600 .elementor-image-box-img img{transition-duration:0.3s;}
    .elementor-5 .elementor-element.elementor-element-3a03600 .elementor-image-box-title{margin-bottom:10px;}
    .elementor-5 .elementor-element.elementor-element-3a03600 > .elementor-widget-container{margin:0em 1em 0em 1em;}
    .elementor-5 .elementor-element.elementor-element-bc78995 .elementor-button{fill:var( --e-global-color-primary );color:var( --e-global-color-primary );background-color:var( --e-global-color-63f36c5 );border-style:none;padding:0px 0px 0px 0px;}
    .elementor-5 .elementor-element.elementor-element-bc78995 .elementor-button:hover,.elementor-5 .elementor-element.elementor-element-bc78995 .elementor-button:focus{color:var( --e-global-color-b0a5f9a );background-color:var( --e-global-color-63f36c5 );}
    .elementor-5 .elementor-element.elementor-element-77944ed > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:2em 2em 2em 2em;}
    .elementor-5 .elementor-element.elementor-element-77944ed > .elementor-element-populated{border-radius:50px 50px 50px 0px;}
    .elementor-5 .elementor-element.elementor-element-77944ed:hover > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-25b493e );}
    .elementor-5 .elementor-element.elementor-element-f3dda56.elementor-position-top .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 .elementor-image-box-img img{transition-duration:0.3s;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 .elementor-image-box-title{margin-bottom:10px;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 > .elementor-widget-container{margin:0em 1em 0em 1em;}
    .elementor-5 .elementor-element.elementor-element-764fb0d .elementor-button{fill:var( --e-global-color-primary );color:var( --e-global-color-primary );background-color:var( --e-global-color-63f36c5 );border-style:none;padding:0px 0px 0px 0px;}
    .elementor-5 .elementor-element.elementor-element-764fb0d .elementor-button:hover,.elementor-5 .elementor-element.elementor-element-764fb0d .elementor-button:focus{color:var( --e-global-color-b0a5f9a );background-color:var( --e-global-color-63f36c5 );}
    .elementor-5 .elementor-element.elementor-element-528ff59 > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:2em 2em 2em 2em;}
    .elementor-5 .elementor-element.elementor-element-528ff59 > .elementor-element-populated{border-radius:50px 0px 50px 50px;}
    .elementor-5 .elementor-element.elementor-element-528ff59:hover > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-25b493e );}
    .elementor-5 .elementor-element.elementor-element-7f23a24.elementor-position-top .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-7f23a24 .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    .elementor-5 .elementor-element.elementor-element-7f23a24 .elementor-image-box-img img{transition-duration:0.3s;}
    .elementor-5 .elementor-element.elementor-element-7f23a24 .elementor-image-box-title{margin-bottom:10px;}
    .elementor-5 .elementor-element.elementor-element-7f23a24 > .elementor-widget-container{margin:0em 1em 0em 1em;}
    .elementor-5 .elementor-element.elementor-element-2bac12c .elementor-button{fill:var( --e-global-color-primary );color:var( --e-global-color-primary );background-color:var( --e-global-color-63f36c5 );border-style:none;padding:0px 0px 0px 0px;}
    .elementor-5 .elementor-element.elementor-element-2bac12c .elementor-button:hover,.elementor-5 .elementor-element.elementor-element-2bac12c .elementor-button:focus{color:var( --e-global-color-b0a5f9a );background-color:var( --e-global-color-63f36c5 );}
    .elementor-5 .elementor-element.elementor-element-d5b8e1f > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:2em 2em 2em 2em;}
    .elementor-5 .elementor-element.elementor-element-d5b8e1f > .elementor-element-populated{border-radius:50px 50px 50px 50px;}
    .elementor-5 .elementor-element.elementor-element-d5b8e1f:hover > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-25b493e );}
    .elementor-5 .elementor-element.elementor-element-332b2f3.elementor-position-top .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-332b2f3 .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    .elementor-5 .elementor-element.elementor-element-332b2f3 .elementor-image-box-img img{transition-duration:0.3s;}
    .elementor-5 .elementor-element.elementor-element-332b2f3 .elementor-image-box-title{margin-bottom:10px;}
    .elementor-5 .elementor-element.elementor-element-332b2f3 > .elementor-widget-container{margin:0em 1em 0em 1em;}
    .elementor-5 .elementor-element.elementor-element-b9699a6 .elementor-button{fill:var( --e-global-color-primary );color:var( --e-global-color-primary );background-color:var( --e-global-color-63f36c5 );border-style:none;padding:0px 0px 0px 0px;}
    .elementor-5 .elementor-element.elementor-element-b9699a6 .elementor-button:hover,.elementor-5 .elementor-element.elementor-element-b9699a6 .elementor-button:focus{color:var( --e-global-color-b0a5f9a );background-color:var( --e-global-color-63f36c5 );}
    .elementor-5 .elementor-element.elementor-element-930e60e > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-b0a5f9a );transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin:1em 1em 1em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:2em 2em 2em 2em;}
    .elementor-5 .elementor-element.elementor-element-930e60e > .elementor-element-populated{border-radius:50px 50px 50px 0px;}
    .elementor-5 .elementor-element.elementor-element-930e60e:hover > .elementor-element-populated{border-style:solid;border-width:1px 1px 1px 1px;border-color:var( --e-global-color-25b493e );}
    .elementor-5 .elementor-element.elementor-element-d29a4ce.elementor-position-top .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce .elementor-image-box-img img{transition-duration:0.3s;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce .elementor-image-box-title{margin-bottom:10px;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce > .elementor-widget-container{margin:0em 1em 0em 1em;}
    .elementor-5 .elementor-element.elementor-element-3129356 .elementor-button{fill:var( --e-global-color-primary );color:var( --e-global-color-primary );background-color:var( --e-global-color-63f36c5 );border-style:none;padding:0px 0px 0px 0px;}
    .elementor-5 .elementor-element.elementor-element-3129356 .elementor-button:hover,.elementor-5 .elementor-element.elementor-element-3129356 .elementor-button:focus{color:var( --e-global-color-b0a5f9a );background-color:var( --e-global-color-63f36c5 );}
    @media (max-width:1024px) and (min-width:768px){
    .elementor-5 .elementor-element.elementor-element-99cc390{width:50%;}
    .elementor-5 .elementor-element.elementor-element-82ab4d9{width:50%;}
    .elementor-5 .elementor-element.elementor-element-77944ed{width:100%;}
    .elementor-5 .elementor-element.elementor-element-528ff59{width:50%;}
    .elementor-5 .elementor-element.elementor-element-d5b8e1f{width:50%;}
    .elementor-5 .elementor-element.elementor-element-930e60e{width:100%;}
    }
    @media (max-width:1024px){
    .elementor-5 .elementor-element.elementor-element-597f45e > .elementor-element-populated{margin:0em 1em 0em 1em;--e-column-margin-right:1em;--e-column-margin-left:1em;padding:3em 3em 3em 3em;}
    .elementor-5 .elementor-element.elementor-element-d8ac617 .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-5 .elementor-element.elementor-element-aa71512 > .elementor-widget-container{margin:0em 3em 0em 3em;}
    .elementor-5 .elementor-element.elementor-element-018377e > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-5 .elementor-element.elementor-element-3a03600 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 .elementor-image-box-wrapper .elementor-image-box-img{width:15%;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-5 .elementor-element.elementor-element-7f23a24 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-5 .elementor-element.elementor-element-332b2f3 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce .elementor-image-box-wrapper .elementor-image-box-img{width:15%;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce > .elementor-widget-container{margin:0em 0em 0em 0em;}
    }
    @media (max-width:767px){
    .elementor-5 .elementor-element.elementor-element-597f45e > .elementor-element-populated{border-radius:40px 40px 40px 40px;}
    .elementor-5 .elementor-element.elementor-element-597f45e > .elementor-element-populated{padding:2em 2em 2em 2em;}
    .elementor-5 .elementor-element.elementor-element-d8ac617 .elementor-heading-title{font-size:var( --e-global-typography-a6f85b6-font-size );line-height:var( --e-global-typography-a6f85b6-line-height );letter-spacing:var( --e-global-typography-a6f85b6-letter-spacing );word-spacing:var( --e-global-typography-a6f85b6-word-spacing );}
    .elementor-5 .elementor-element.elementor-element-aa71512 > .elementor-widget-container{margin:0em 0em 0em 0em;}
    .elementor-5 .elementor-element.elementor-element-99cc390 > .elementor-element-populated{border-radius:40px 0px 40px 40px;}
    .elementor-5 .elementor-element.elementor-element-99cc390 > .elementor-element-populated{margin:0em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-5 .elementor-element.elementor-element-018377e .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-82ab4d9 > .elementor-element-populated{border-radius:40px 40px 40px 40px;}
    .elementor-5 .elementor-element.elementor-element-82ab4d9 > .elementor-element-populated{margin:2em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-5 .elementor-element.elementor-element-3a03600 .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-77944ed > .elementor-element-populated{border-radius:40px 40px 40px 0px;}
    .elementor-5 .elementor-element.elementor-element-77944ed > .elementor-element-populated{margin:2em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-f3dda56 .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    .elementor-5 .elementor-element.elementor-element-528ff59 > .elementor-element-populated{border-radius:40px 0px 40px 40px;}
    .elementor-5 .elementor-element.elementor-element-528ff59 > .elementor-element-populated{margin:2em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-5 .elementor-element.elementor-element-7f23a24 .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-d5b8e1f > .elementor-element-populated{border-radius:40px 40px 40px 40px;}
    .elementor-5 .elementor-element.elementor-element-d5b8e1f > .elementor-element-populated{margin:2em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-5 .elementor-element.elementor-element-332b2f3 .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-930e60e > .elementor-element-populated{border-radius:40px 40px 40px 0px;}
    .elementor-5 .elementor-element.elementor-element-930e60e > .elementor-element-populated{margin:2em 0em 0em 0em;--e-column-margin-right:0em;--e-column-margin-left:0em;padding:1em 1em 1em 1em;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce .elementor-image-box-img{margin-bottom:0px;}
    .elementor-5 .elementor-element.elementor-element-d29a4ce .elementor-image-box-wrapper .elementor-image-box-img{width:35%;}
    }
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/css/all.min.css?ver=3.15.1 ; media=all */
    @media all{
    .fas{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
    .fa-arrow-right:before{content:"\f061";}
    .fas{font-family:"Font Awesome 5 Free";}
    .fas{font-weight:900;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementskit-lite/widgets/init/assets/css/widget-styles.css?ver=2.9.0 ; media=all */
    @media all{
    :focus{outline:0;}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3 ; media=all */
    @media all{
    .fas{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
    .fa-arrow-right:before{content:"\f061";}
    }
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3 ; media=all */
    @media all{
    .fas{font-family:"Font Awesome 5 Free";font-weight:900;}
    }
    /*! CSS Used from: Embedded */
    .elementor-heading-title{padding:0;margin:0;line-height:1;}
    /*! CSS Used from: Embedded */
    .elementor-widget-image-box .elementor-image-box-content{width:100%;}
    @media (min-width:768px){
    .elementor-widget-image-box.elementor-position-top .elementor-image-box-img{margin:auto;}
    }
    @media (max-width:767px){
    .elementor-widget-image-box .elementor-image-box-img{margin-left:auto!important;margin-right:auto!important;margin-bottom:15px;}
    }
    .elementor-widget-image-box .elementor-image-box-img{display:inline-block;}
    .elementor-widget-image-box .elementor-image-box-wrapper{text-align:center;}
    .elementor-widget-image-box .elementor-image-box-description{margin:0;}
    /*! CSS Used from: https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=3.15.1 ; media=all */
    @media all{
    .fadeInLeft{animation-name:fadeInLeft;}
    .fadeInRight{animation-name:fadeInRight;}
    .fadeInUp{animation-name:fadeInUp;}
    .elementor-animation-grow{transition-duration:.3s;transition-property:transform;}
    .elementor-animation-grow:active,.elementor-animation-grow:focus,.elementor-animation-grow:hover{transform:scale(1.1);}
    }
    /*! CSS Used keyframes */
    @-webkit-keyframes fadeInLeft{from{opacity:0;-webkit-transform:translateX(-100%);transform:translateX(-100%);}to{opacity:1;}}
    @keyframes fadeInLeft{from{opacity:0;-webkit-transform:translateX(-100%);transform:translateX(-100%);}to{opacity:1;}}
    @keyframes fadeInLeft{from{opacity:0;transform:translate3d(-100%,0,0);}to{opacity:1;transform:none;}}
    @-webkit-keyframes fadeInRight{from{opacity:0;-webkit-transform:translateX(100%);transform:translateX(100%);}to{opacity:1;}}
    @keyframes fadeInRight{from{opacity:0;-webkit-transform:translateX(100%);transform:translateX(100%);}to{opacity:1;}}
    @keyframes fadeInRight{from{opacity:0;transform:translate3d(100%,0,0);}to{opacity:1;transform:none;}}
    @keyframes fadeInUp{from{opacity:0;transform:translate3d(0,100%,0);}to{opacity:1;transform:none;}}
    /*! CSS Used fontfaces */
    @font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:400;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-regular-400.svg#fontawesome) format("svg");}
    @font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.svg#fontawesome) format("svg");}
    @font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:block;src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot);src:url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff2) format("woff2"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.woff) format("woff"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.ttf) format("truetype"),url(https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts/fa-solid-900.svg#fontawesome) format("svg");}