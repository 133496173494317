.background-image-container {
    position: relative;
    width: 100%;
    height: 50vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('https://presencelearning.com/wp-content/uploads/2022/06/OT-B-Featured-content-1024x686.jpg');
    background-size: cover;
    background-position: center;
  }
  
  /* .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  } */
  
  .overlay h1 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .overlay p {
    font-size: 1.2rem;
  }
  