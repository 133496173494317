.fade-in {
    animation: fade-in-animation 1s ease-in-out forwards;
    opacity: 0;
  }
  
  @keyframes fade-in-animation {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  